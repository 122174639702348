import Grid from "elements/Grid";
import GeorgiaCountiesServedMap from "public/landing/state-pages/georgia-counties-served.svg";
import NewYorkCountiesServedMap from "public/landing/state-pages/newyork-counties-served.svg";
import statePages from "config/state-pages.json";
import moment from "moment";
import { StatesWithStateSpecificPage } from "utils/types";

const STATES_SERVED = {
	GA: {
		Map: <GeorgiaCountiesServedMap />,
		DATE_TABLE_BODY:
			"It's important to stay on top of important dates in your county. The deadline to appeal is typically 45 days from when your assessment notice is mailed out by the county.",
		COUNTIES_SERVED_OVERRIDE: [],
		COUNTIES_SERVED: [
			{
				county: "Cherokee",
				deadlineDate: "2024-06-27",
				noticeDate: "2024-05-13",
			},
			{
				county: "Clayton",
				deadlineDate: "2024-06-10",
				noticeDate: "2024-04-26",
			},
			{ county: "Cobb", deadlineDate: "2024-06-17", noticeDate: "2024-05-11" },
			{
				county: "DeKalb",
				deadlineDate: "2024-07-10",
				noticeDate: "2024-05-26",
			},
			{
				county: "Douglas",
				deadlineDate: "2024-08-07",
				noticeDate: "2024-06-23",
			},
			{
				county: "Fulton",
				deadlineDate: "2024-07-24",
				noticeDate: "2024-06-09",
			},
			{
				county: "Gwinnett",
				deadlineDate: "2024-05-20",
				noticeDate: "2024-04-07",
			},
			{ county: "Henry", deadlineDate: "2024-05-03", noticeDate: "2024-05-03" },
			{
				county: "Paulding",
				deadlineDate: "2024-05-24",
				noticeDate: "2024-05-24",
			},
			{
				county: "Richmond",
				deadlineDate: "2024-06-15",
				noticeDate: "2024-06-15",
			},
			{
				county: "Rockdale",
				deadlineDate: "2024-04-19",
				noticeDate: "2024-04-19",
			},
		],
	},
	NY: {
		Map: <NewYorkCountiesServedMap />,
		DATE_TABLE_BODY:
			"It's important to stay on top of important dates as filing deadlines vary between municipalities.",
		COUNTIES_SERVED_OVERRIDE: [
			{
				county: "Nassau",
			},
			{
				county: "Suffolk",
			},
			{
				county: "Westchester",
			},
		],
		COUNTIES_SERVED: [
			{
				county: "Nassau - City of Long Beach",
				deadlineDate: "2024-01-16",
				noticeDate: "2024-01-05",
			},
			{
				county: "Westchester - City of White Plains",
				deadlineDate: "2024-01-22",
				noticeDate: "2024-01-02",
			},
			{
				county: "Nassau - Oyster Bay / Massapequa Park",
				deadlineDate: "2024-02-15",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - Hempstead / Hempstead",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - Hempstead / Garden City",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - Hempstead / Lynbrook",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - Hempstead / Rockville Centre",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - Hempstead / Valley Stream",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - Hempstead / Floral Park",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - Hempstead / Malverne",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - Hempstead / Mineola",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - Hempstead / East Rockaway",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-02",
			},
			{
				county: "Nassau - Hempstead / New Hyde Park",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - North Hempstead / Lake Success",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - North Hempstead / Sands Point",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - North Hempstead / East Hills",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - North Hempstead / Great Neck",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - North Hempstead / Williston Park",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau - North Hempstead / Sea Cliff",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-06-01",
			},
			{
				county: "Nassau - North Hempstead / Farmingdale",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau",
				deadlineDate: "2024-03-18",
				noticeDate: "2024-01-01",
			},
			{
				county: "Suffolk - Babylon",
				deadlineDate: "2024-05-21",
				noticeDate: "2024-05-01",
			},
			{
				county: "Suffolk - Brookhaven",
				deadlineDate: "2024-05-21",
				noticeDate: "2024-02-01",
			},
			{
				county: "Suffolk - East Hampton",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Suffolk - Huntington",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Suffolk - Islip",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Suffolk - Riverhead",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Suffolk - Shelter Island",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Suffolk - Smithtown",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Suffolk - Southampton",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Suffolk - Southold",
				deadlineDate: "2024-02-20",
				noticeDate: "2024-02-01",
			},
			{
				county: "Nassau / City of Glen Cove",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - City of Mount Vernon",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - City of New Rochelle",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - City of Peekskill",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - City of Rye",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Bedford",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Cortlandt",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Eastchester",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Greenburgh",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Harrison",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Lewisboro",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Mamaroneck",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Mount Kisco",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Mount Pleasant",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - New Castle",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - North Castle",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - North Salem",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Ossining",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Pelham",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Pound Ridge",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Rye",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Scarsdale",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Somers",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - Yorktown",
				deadlineDate: "2024-06-18",
				noticeDate: "2024-06-01",
			},
			{
				county: "Westchester - City of Yonkers",
				deadlineDate: "2024-11-15",
				noticeDate: "2024-11-01",
			},
			{
				county: "Nassau - Hempstead / Freeport",
				deadlineDate: "2024-11-19",
				noticeDate: "2024-11-01",
			},
		],
	},
};

export const CountiesServedAndDates = ({
	target,
}: {
	target: StatesWithStateSpecificPage;
}) => {
	const activeCountiesToShowUnderCurrentCounties =
		STATES_SERVED[target].COUNTIES_SERVED_OVERRIDE.length > 0
			? STATES_SERVED[target].COUNTIES_SERVED_OVERRIDE
			: STATES_SERVED[target].COUNTIES_SERVED;

	return (
		<div id="landing-counties-served-and-dates">
			<div id="landing-counties-served">
				<Grid>
					<h3 id="counties-served-title" className="h3-update">
						Current counties we serve
					</h3>
					<div id="counties-served-map">{STATES_SERVED[target].Map}</div>
					<div id="counties-served-content">
						<p className="denim-medium mb-2">
							Ownwell currently serves{" "}
							{activeCountiesToShowUnderCurrentCounties.length} counties:
						</p>
						<div id="counties-served-boxes" className="mb-2">
							{activeCountiesToShowUnderCurrentCounties.map(({ county }, i) => (
								<div key={i} className="sm landing-counties-served-county-box">
									<p className="sm landing-counties-served-county-text">
										{county}
									</p>
								</div>
							))}
						</div>
						<p className="sm denim-medium">
							New counties will be launched based on demand
						</p>
					</div>
				</Grid>
			</div>
			<div id="landing-counties-dates">
				<Grid>
					<h3 className="h3-update" id="landing-counties-dates-title">
						Important {target ? statePages[target].name + " " : ""} dates
					</h3>
					<p className="denim-medium mb-2 landing-counties-paragraph">
						{STATES_SERVED[target].DATE_TABLE_BODY}
					</p>
					<div className="landing-counties-dates">
						<div className="landing-counties-dates-table-container">
							<table className="landing-counties-dates-table">
								<thead>
									<tr>
										<th className="landing-counties-dates-header">
											<p className="sm bold">County Name</p>
										</th>
										<th className="landing-counties-dates-header">
											<p className="sm bold">Estimated notice mailing date</p>
										</th>
										<th className="landing-counties-dates-header">
											<p className="sm bold">Estimated deadline</p>
										</th>
									</tr>
								</thead>
								<tbody>
									{STATES_SERVED[target].COUNTIES_SERVED.map(
										({ county, deadlineDate, noticeDate }, i) => (
											<tr key={"states-served-td-" + i}>
												<td>
													<p className="sm">{county}</p>
												</td>
												<td>
													<p className="sm">
														{moment(noticeDate).format("MMMM DD")}
													</p>
												</td>
												<td>
													<p className="sm">
														{moment(deadlineDate).format("MMMM DD")}
													</p>
												</td>
											</tr>
										)
									)}
								</tbody>
							</table>
						</div>
						<p className="sm denim-medium mt-2">
							*Estimated mailing notice and deadline dates are only estimates
							and not to be used as confirmed dates
						</p>
					</div>
				</Grid>
			</div>
		</div>
	);
};
